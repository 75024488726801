var stickyButton = document.querySelector('.nav-open');
var stickyButtonInner = document.querySelector('.icons-wrapper');
var navbarElem = document.querySelector('nav.nav-container');
var pageMain = document.querySelector('main');
var navCloseBg = document.querySelector('.nav-close-bg');
var navLinks = document.querySelectorAll('a.nav-link');
var CircleWrapper = document.querySelector('.addon-layer-wrapper');
var navbarActive = false;
stickyButtonInner.addEventListener('mousemove', function () { });
stickyButton.addEventListener('mousemove', function (e) {
    // stickyButton.className += ' active'
    stickyButton.style.transition =
        'scale 0.2s cubic-bezier(0.45, 0.02, 0.09, 0.98)';
    stickyButtonInner.style.transition =
        'scale 0.2s cubic-bezier(0.45, 0.02, 0.09, 0.98)';
    var pos = stickyButton.getBoundingClientRect();
    var mx = e.clientX - pos.left - pos.width / 2;
    var my = e.clientY - pos.top - pos.height / 2;
    stickyButton.style.transform = "\n        translate(".concat(mx * 0.1, "px ,").concat(my * 0.2, "px)\n    ");
    stickyButtonInner.style.transform = "\n        translate(".concat(mx * 0.08, "px ,").concat(my * 0.15, "px)\n    ");
});
stickyButton.addEventListener('mouseleave', function () {
    stickyButton.style.transition =
        'all 0.2s cubic-bezier(0.45, 0.02, 0.09, 0.98)';
    stickyButtonInner.style.transition =
        'all 0.2s cubic-bezier(0.45, 0.02, 0.09, 0.98)';
    stickyButton.style.transform = 'none';
    stickyButtonInner.style.transform = 'none';
});
stickyButton.onclick = function () {
    navbarActive = !navbarActive;
    navStuff();
};
navCloseBg.onclick = function () {
    navbarActive = false;
    navStuff();
};
navLinks.forEach(function (e) {
    e.addEventListener('click', function () {
        navbarActive = false;
        navStuff();
    });
});
function navStuff() {
    if (navbarActive) {
        stickyButton.className += ' active';
        navbarElem.className += ' active';
        navCloseBg.className += ' active';
        CircleWrapper.style.animation =
            '1s addon-on-open cubic-bezier(0.83, 0.11, 0.25, 0.95) forwards';
        // pageMain.style.filter = 'blur(15px)'
        // pageMain.style.pointerEvents = 'none'
    }
    else {
        stickyButton.className = 'nav-open';
        navbarElem.className = 'nav-container';
        CircleWrapper.style.animation =
            '1s addon-on-close cubic-bezier(0.83, 0.11, 0.25, 0.95) forwards';
        navCloseBg.className = 'nav-close-bg';
        // pageMain.style.filter = 'blur(0)'
        // pageMain.style.pointerEvents = 'all'
    }
}
